import { useMutation } from "@tanstack/vue-query";

const updateOwnAccess = async (variables) => {
  return await fetchWithAuth("/api/currentUser/ownAccess", {
    method: "POST",
    body: variables,
  });
};

export const useUpdateOwnAccessMutation = () => {
  return useMutation({
    mutationFn: updateOwnAccess,
  });
};
