<script setup>
import { useNotification } from "@kyvg/vue3-notification";
import { useQueryClient } from "@tanstack/vue-query";
const queryClient = useQueryClient();

const props = defineProps(["collaboratorInvites", "usersName"]);

const { notify } = useNotification();

const currentInviteIndex = ref(0);

const loadingAccept = ref(false);
const loadingReject = ref(false);

const modalStore = useModalStore();

const { mutate: respondToCollaboratorInvite } =
  useRespondToCollaboratorInviteMutation();

const currentInvite = computed(
  () => props.collaboratorInvites[currentInviteIndex.value],
);

const goToNextInvite = () => {
  if (props.collaboratorInvites.length > currentInviteIndex.value + 1) {
    currentInviteIndex.value++;
  } else {
    modalStore.closeModal();
  }
};

const rejectInvite = () => {
  if (loadingAccept.value || loadingReject.value) {
    return;
  }
  loadingReject.value = true;

  respondToCollaboratorInvite(
    {
      ...currentInvite.value,
      response: "reject",
    },
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.refetchQueries(["collaborator_invites"]),
          queryClient.refetchQueries(["brands"]),
        ]);
        notify({
          type: "success",
          text: `You have rejected the invite to collaborate on ${currentInvite.value.brandNAme}`,
        });
        goToNextInvite();
        loadingReject.value = false;
      },
      onError: () => {
        notify({
          type: "error",
          text: `Something went wrong`,
        });
        loadingReject.value = false;
      },
    },
  );
};

const acceptInvite = () => {
  if (loadingAccept.value || loadingReject.value) {
    return;
  }

  loadingAccept.value = true;

  respondToCollaboratorInvite(
    {
      ...currentInvite.value,
      response: "accept",
    },
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.refetchQueries(["collaborator_invites"]),
          queryClient.refetchQueries(["brands"]),
        ]);
        notify({
          type: "success",
          text: `You can now ${
            currentInvite.value.role == "viewer" ? "view" : "edit"
          } <strong>${currentInvite.value.brandName}</strong>`,
        });
        goToNextInvite();
        loadingAccept.value = false;
      },
      onError: () => {
        notify({
          type: "error",
          text: `Something went wrong`,
        });
        loadingAccept.value = false;
      },
    },
  );
};
</script>

<template>
  <div>
    <Transition mode="out-in" name="slide">
      <div
        :key="`invite-${currentInvite.ownerId}-${currentInvite.brandName}`"
        class="flex flex-col items-center text-center"
      >
        <div class="mb-[30px]">
          <CircleAvatarGroup
            :userName="usersName"
            :otherNames="[currentInvite.ownerName]"
          />
        </div>

        <div class="mb-[10px]">
          <Text variant="widget_title">You're invited to collaborate</Text>
        </div>

        <div class="mb-[30px]">
          <Text
            >You have been invited to by {{ currentInvite.ownerName }} to
            <strong>{{ currentInvite.brandName }}</strong> as an
            <strong>{{ currentInvite.role }}</strong></Text
          >
        </div>

        <div class="space-x-2">
          <Button
            size="lg"
            :loading="loadingReject"
            @click="rejectInvite"
            variant="secondary-outlined"
          >
            Reject
          </Button>
          <Button size="lg" :loading="loadingAccept" @click="acceptInvite">
            Accept
          </Button>
        </div>
      </div>
    </Transition>

    <div class="mb-[148px]">
      <img
        src="/graphic-invited.svg"
        class="absolute bottom-0 w-[178px] h-[160px] left-1/2 -translate-x-1/2"
      />
    </div>
  </div>
</template>

<style>
/* we will explain what these classes do next! */
.slide-enter-active,
.slide-leave-active {
  transition:
    opacity 0.3s ease-in-out,
    transform 0.3s ease-in-out;
}

.slide-enter-from {
  transform: translateX(-75px);
}
.slide-leave-to {
  transform: translateX(75px);
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
}
</style>
