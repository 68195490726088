import { useMutation } from "@tanstack/vue-query";

const respondToCollaborator = async (variables) => {
  return await fetchWithAuth("/api/currentUser/respondToCollaboratorInvite", {
    method: "POST",
    body: variables,
  });
};

export const useRespondToCollaboratorInviteMutation = () => {
  return useMutation({
    mutationFn: respondToCollaborator,
  });
};
