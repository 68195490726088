import { useMutation } from "@tanstack/vue-query";

const updateCollaborator = async ({ collaboratorId, ...collaboratorData }) => {
  return await fetchWithAuth(
    `/api/currentUser/collaborators/${collaboratorId}`,
    {
      method: "PATCH",
      body: collaboratorData,
    }
  );
};

export const useUpdateCollaboratorMutation = () => {
  return useMutation({
    mutationFn: updateCollaborator,
  });
};
