<script setup>
const { data, isLoading } = useCurrentUserBrandsQuery();

const modalStore = useModalStore();
</script>

<template>
  <DialogHeader>
    <DialogTitle>Invite user</DialogTitle>
    <Text>Invite a new user to edit or view brands that you manage.</Text>
  </DialogHeader>
  <div v-if="isLoading">loading</div>
  <div v-else>
    <FormsInviteUser :brands="data.brands" @success="modalStore.closeModal" />
  </div>
</template>
