<script setup>
import { useQueryClient } from "@tanstack/vue-query";
import { useNotification } from "@kyvg/vue3-notification";

const modalStore = useModalStore();

const { notify } = useNotification();

const props = defineProps({
  collaboratorId: { type: String, required: true },
  brandAccess: { type: Array, required: false },
  email: { type: String, required: true },
});

const { mutate: deleteCollaborator } = useDeleteCollaboratorMutation();

const isSubmitting = ref(false);

const queryClient = useQueryClient();

const onSubmit = () => {
  if (isSubmitting.value) {
    return;
  }

  isSubmitting.value = true;

  deleteCollaborator(
    { collaboratorId: props.collaboratorId },
    {
      onSuccess: async (data) => {
        await queryClient.refetchQueries(["collaborators"]);
        console.log(data);
        notify({
          type: "success",
          text: `<strong>${props.email}</strong> has been removed`,
        });
        modalStore.closeModal();
      },
      onError: async (data) => {
        notify({ type: "error", text: "Something went wrong" });
      },
    },
  );
};
</script>

<template>
  <DialogHeader>
    <DialogTitle>Revoke user access</DialogTitle>
  </DialogHeader>

  <Text class="mb-1"
    >Are you sure you would like to stop sharing your brands with
    <strong>{{ email }}</strong
    >?</Text
  >

  <Text class="mb-4"
    >They will no longer be able to edit or view
    {{ brandAccess.length }} brands</Text
  >

  <Button
    @click="onSubmit"
    size="lg"
    :loading="isSubmitting"
    variant="destructive"
    >Stop sharing</Button
  >
  <Button variant="secondary-outlined" size="lg" @click="modalStore.closeModal"
    >Cancel</Button
  >

  <Text class="text-center" variant="body_light_sm"
    >We will send email informing them of the change in their access.</Text
  >
</template>
