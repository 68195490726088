<script setup>
const props = defineProps(["brandAccess", "name", "email", "collaboratorId"]);
const { data, isLoading } = useCurrentUserBrandsQuery();

const modalStore = useModalStore();
</script>

<template>
  <DialogHeader>
    <DialogTitle>Manage user access </DialogTitle>
    <Text class="mb-4"
      >Update the permissions for <strong>{{ props.email }}</strong></Text
    >
  </DialogHeader>
  <div v-if="isLoading">loading</div>
  <div v-else>
    <FormsUpdateUserAccess
      :collaboratorId="collaboratorId"
      :email="email"
      :name="name"
      :brands="data.brands"
      :brand-access="brandAccess"
      @success="modalStore.closeModal"
    />
  </div>
</template>
