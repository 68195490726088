<script setup>
import * as z from "zod";
import { toTypedSchema } from "@vee-validate/zod";
import { useForm } from "vee-validate";
import { useQueryClient } from "@tanstack/vue-query";
import { useNotification } from "@kyvg/vue3-notification";

const { notify } = useNotification();

const props = defineProps(["brands", "sharedBrands"]);
const emit = defineEmits(["success"]);

const queryClient = useQueryClient();

const formSchema = toTypedSchema(
  z.object({
    brands: z.record(z.string(), z.string()),
  }),
);

const getInitialValues = () => {
  const brands = {};

  props.brands.forEach((brand) => {
    const existingAccessLevel = "owner";

    brands[brand.id] = existingAccessLevel;
  });

  props.sharedBrands.forEach((brand) => {
    brands[brand.id] = brand.canEdit ? "editor" : "viewer";
  });

  return { brands };
};

const form = useForm({
  validationSchema: formSchema,
  initialValues: getInitialValues(),
});

const { mutate: updateOwnAccess } = useUpdateOwnAccessMutation();

const isSubmitting = ref(false);

const onSubmit = form.handleSubmit(async (values) => {
  if (isSubmitting.value) return;

  let givingBrandAccess = false;

  props.brands.forEach((brand) => {
    if (values.brands[brand.id] !== "none") {
      givingBrandAccess = true;
    }
  });

  isSubmitting.value = true;

  updateOwnAccess(
    { ...values },
    {
      onError: (error) => {
        isSubmitting.value = false;

        let errorMessage = "Something went wrong";

        notify({ type: "error", text: errorMessage });
      },
      onSuccess: async () => {
        console.log("Hell yeah");

        await queryClient.refetchQueries(["collaborators"]);

        notify({
          type: "success",
          text: `Your access has been updated`,
        });

        emit("success");
      },
    },
  );
});
</script>

<template>
  <form @submit="onSubmit" novalidate class="flex flex-col gap-8">
    <FormField v-slot="{ componentField }" name="brands">
      <BrandAccessSelect
        :brands="[...brands, ...sharedBrands]"
        v-bind="componentField"
        :editingOwnAccess="true"
        :initialAccessLevels="getInitialValues().brands"
      />
    </FormField>

    <AccessChangeWarning
      :brands="[...brands, ...sharedBrands]"
      :newAccess="form.values.brands"
      :editingOwnAccess="true"
      :initialAccessLevels="getInitialValues().brands"
    />

    <Button :loading="isSubmitting" size="lg" type="submit"> Update </Button>
    <Text class="text-center" variant="body_light_sm"
      >We will send email informing them of the change in their access.</Text
    >
  </form>
</template>
