<script setup>
const props = defineProps([
  "brands",
  "modelValue",
  "editingOwnAccess",
  "initialAccessLevels",
]);

const roles = [
  { id: "none", name: "Not shared" },
  { id: "viewer", name: "Can view" },
  { id: "editor", name: "Can edit" },
];

const getOptionsForRole = (brandId) => {
  if (props.editingOwnAccess) {
    const value = props.initialAccessLevels[brandId];

    console.log(props.initialAccessLevels);
    console.log(value);
    if (value === "editor") {
      return roles;
    }

    if (value == "viewer") {
      return [
        { id: "none", name: "Not shared" },
        { id: "viewer", name: "Can view" },
      ];
    }
  }

  return roles;
};
</script>

<template>
  <div class="space-y-1">
    <FormLabel class="pointer-events-none"> Access </FormLabel>

    <table class="w-full border-spacing-y-8">
      <tr v-for="brand in brands" class="border-y border-mono-silver">
        <td class="w-1/2">
          <Text variant="body_semibold">{{ brand.name }}</Text>
        </td>
        <td class="w-1/2 py-[10px]">
          <Select class="w-full" v-model="modelValue[brand.id]">
            <FormControl>
              <SelectTrigger
                class="h-[40px]"
                :disabled="modelValue[brand.id] == 'owner'"
              >
                <SelectValue />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              <SelectGroup>
                <SelectItem v-if="modelValue[brand.id] == 'owner'" value="owner"
                  >Owner</SelectItem
                >
                <SelectItem
                  v-for="role in getOptionsForRole(brand.id)"
                  :value="role.id"
                >
                  {{ role.name }}</SelectItem
                >
              </SelectGroup>
            </SelectContent>
          </Select>
        </td>
      </tr>
    </table>
    <FormMessage />
  </div>
</template>
