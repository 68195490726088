<script setup>
const props = defineProps(["brandAccess", "email", "collaboratorId"]);
const { data, isLoading } = useCurrentUserBrandsQuery();

const modalStore = useModalStore();
</script>

<template>
  <DialogHeader>
    <DialogTitle>Manage my access</DialogTitle>
    <Text class="mb-4" color="helper">Update your access to other brands.</Text>
  </DialogHeader>
  <div v-if="isLoading">loading</div>
  <div v-else>
    <FormsUpdateOwnAccess
      :brands="data.brands"
      :sharedBrands="data.sharedBrands"
      @success="modalStore.closeModal"
    />
  </div>
</template>
