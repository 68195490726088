import { useMutation } from "@tanstack/vue-query";

const inviteCollaborator = async (collaboratorData) => {
  return await fetchWithAuth("/api/currentUser/collaborators", {
    method: "POST",
    body: { collaboratorData },
  });
};

export const useInviteCollaboratorMutation = () => {
  return useMutation({
    mutationFn: inviteCollaborator,
  });
};
