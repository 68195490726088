<script setup>
const props = defineProps([
  "brands",
  "newAccess",
  "editingOwnAccess",
  "initialAccessLevels",
  "name",
  "email",
]);

const editingRoles = ["editor"];
const viewingRoles = ["editor", "viewer"];

const identifier = computed(() =>
  props.editingOwnAccess ? "You" : props.name || props.email,
);

const accessChanges = computed(() => {
  const lostView = [];
  const lostEdit = [];
  props.brands.forEach((brand) => {
    const initialRole = props.initialAccessLevels[brand.id];
    const newRole = props.newAccess[brand.id];

    const canViewBefore = viewingRoles.includes(initialRole);
    const canViewAfter = viewingRoles.includes(newRole);

    if (canViewBefore && !canViewAfter) {
      lostView.push(brand.name);
    } else {
      const canEditBefore = editingRoles.includes(initialRole);
      const canEditAfter = editingRoles.includes(newRole);

      if (canEditBefore && !canEditAfter) {
        lostEdit.push(brand.name);
      }
    }
  });

  return { lostView, lostEdit };
});

const accessChangesString = computed(() => {
  let viewString = "";

  if (accessChanges.value.lostView.length) {
    if (accessChanges.value.lostView.length == 1) {
      viewString += `${identifier.value} will no longer be able to access ${accessChanges.value.lostView[0]}.`;
    } else {
      const brands = [...accessChanges.value.lostView];
      const lastBrand = brands.pop();
      viewString += `${
        identifier.value
      } will no longer be able to access ${brands.join(
        ", ",
      )} and ${lastBrand}.`;
    }
  }

  let editString = "";

  if (accessChanges.value.lostEdit.length) {
    if (accessChanges.value.lostEdit.length == 1) {
      editString += `${identifier.value} will no longer be able to edit ${accessChanges.value.lostEdit[0]}.`;
    } else {
      const brands = [...accessChanges.value.lostEdit];
      const lastBrand = brands.pop();
      editString += `${
        identifier.value
      } will no longer be able to edit ${brands.join(", ")} and ${lastBrand}.`;
    }
  }

  return [viewString, editString].filter((s) => s.length).join(" ");
});
</script>

<template>
  <Notice
    v-if="accessChangesString.length"
    variant="primary"
    class="w-full text-center"
    ><Text as="span" v-if="accessChanges.lostView">{{
      accessChangesString
    }}</Text></Notice
  >
</template>
