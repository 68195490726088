<script setup>
import * as z from "zod";
import { toTypedSchema } from "@vee-validate/zod";
import { useForm } from "vee-validate";
import { useQueryClient } from "@tanstack/vue-query";
import { useNotification } from "@kyvg/vue3-notification";

const { notify } = useNotification();

const props = defineProps(["brands"]);
const emit = defineEmits(["success"]);

const queryClient = useQueryClient();

const formSchema = toTypedSchema(
  z.object({
    email: z
      .string({
        required_error:
          "You need to add an email address to invite to this account.",
      })
      .email("You need to add an email address to invite to this account."),
    brands: z.record(z.string(), z.string()),
  }),
);

const { mutate: inviteCollaborator } = useInviteCollaboratorMutation();

const getInitialValues = () => {
  const brands = {};

  props.brands.forEach((brand) => (brands[brand.id] = "none"));

  return { brands };
};

const form = useForm({
  validationSchema: formSchema,
  initialValues: getInitialValues(),
});

const isSubmitting = ref(false);

const onSubmit = form.handleSubmit(async (values) => {
  if (isSubmitting.value) return;

  let givingBrandAccess = false;

  props.brands.forEach((brand) => {
    if (values.brands[brand.id] !== "none") {
      givingBrandAccess = true;
    }
  });

  if (!givingBrandAccess) {
    console.log("Error!");
    form.setFieldError(
      "brands",
      "You must allow edit or view access to at least one brand",
    );
    return;
  }

  isSubmitting.value = true;

  inviteCollaborator(values, {
    onError: (error) => {
      console.log("Woopsies");
      isSubmitting.value = false;

      console.log(error.data.message);

      let errorMessage = "Something went wrong";

      if (error.data.message == "cannot_invite_yourself") {
        errorMessage = "You cannot share brands with yourself";
      } else if ((error.data.message = "already_invited")) {
        errorMessage = `You already share brands with ${values.email}`;
      }
      notify({ type: "error", text: errorMessage });
    },
    onSuccess: async () => {
      console.log("Hell yeah");

      await queryClient.refetchQueries(["collaborators"]);

      notify({ type: "success", text: `${values.email} has been invited` });

      emit("success");
    },
  });
});
</script>

<template>
  <form @submit="onSubmit" novalidate class="flex flex-col gap-8">
    <FormField v-slot="{ componentField }" name="email">
      <FormItem>
        <div class="space-y-2">
          <FormLabel class="pointer-events-none">
            User's email address</FormLabel
          >
        </div>

        <FormControl>
          <Input
            type="text"
            placeholder="john@example.com"
            v-bind="componentField"
            maxlength="100"
          />
          <FormMessage />
        </FormControl>
      </FormItem>
    </FormField>

    <FormField v-slot="{ componentField }" name="brands">
      <BrandAccessSelect :brands="brands" v-bind="componentField" />
    </FormField>

    <Button :loading="isSubmitting" size="lg" type="submit"> Invite </Button>

    <Text class="text-center" variant="body_light_sm"
      >We will send invite for them to join the brands you’ve chosen. If they
      don’t have an account, they can get one for free.</Text
    >
  </form>
</template>
