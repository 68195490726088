<script setup>
import { useI18n } from "vue-i18n";

import { useNotification } from "@kyvg/vue3-notification";

import * as z from "zod";
import { toTypedSchema } from "@vee-validate/zod";
import { useForm } from "vee-validate";

import { useQueryClient } from "@tanstack/vue-query";

const props = defineProps({
  brand: { type: Object, required: true },
});

const nuxtApp = useNuxtApp();
const i18n = useI18n();
const queryClient = useQueryClient();

const modalStore = useModalStore();

const { mutate: deleteBrand } = useDeleteBrandMutation();

const showDeletionConfirmation = ref(false);

const { notify } = useNotification();

const onBrandDelete = async () => {
  isLoadingDeletion.value = true;

  deleteBrand(
    { brandId: props.brand?.id },
    {
      onSuccess: async (data) => {
        await Promise.allSettled([
          queryClient.refetchQueries({
            queryKey: ["brands"],
          }),
          queryClient.refetchQueries({ queryKey: ["user"] }),
          queryClient.refetchQueries({ queryKey: ["collaborators"] }),
        ]);
        await navigateTo(nuxtApp.$localePath("/"));

        notify({
          type: "success",
          text: i18n.t("edit-brand.brand-deleted", [data.name]),
        });
        modalStore.closeModal();
        isLoadingDeletion.value = false;
      },
      onError: () => {
        notify({
          type: "error",
          text: i18n.t("edit-brand.something-went-wrong"),
        });
        console.error("Error updating brand!");
        isLoadingDeletion.value = false;
      },
    },
  );
};

const isLoadingDeletion = ref(false);
</script>

<template>
  <div class="flex flex-col gap-4">
    <DialogHeader>
      <DialogTitle>{{ $t("edit-brand.delete-title") }}</DialogTitle>
    </DialogHeader>
    <Text class="mb-5"
      ><span
        class="break-words"
        v-html="$t('edit-brand.are-you-sure', [brand?.name])"
      ></span
    ></Text>
    <Alert class="mb-5" v-if="brand?.numberLabels"
      ><Text v-if="brand?.numberLabels > 1">
        <span
          v-html="
            $t('edit-brand.delete-existing-labels', [brand?.numberLabels])
          "
        >
        </span>
      </Text>

      <Text v-else="brand?.numberLabels == 1">
        <span v-html="$t('edit-brand.delete-one-label')"></span> </Text
    ></Alert>
    <Button
      type="button"
      variant="destructive"
      :fullwidth="true"
      size="lg"
      @click.prevent="onBrandDelete"
      :loading="isLoadingDeletion"
      >{{ $t("edit-brand.delete-action") }}</Button
    >
    <Button
      type="button"
      variant="secondary-outlined"
      :fullwidth="true"
      size="lg"
      @click="modalStore.closeModal"
      >{{ $t("edit-brand.delete-cancel") }}</Button
    >
    <Text variant="body_light_sm" class="text-center text-pretty mx-auto">{{
      $t("edit-brand.warning")
    }}</Text>
  </div>
</template>
