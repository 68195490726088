<script setup>
import * as z from "zod";
import { toTypedSchema } from "@vee-validate/zod";
import { useForm } from "vee-validate";
import { useQueryClient } from "@tanstack/vue-query";
import { useNotification } from "@kyvg/vue3-notification";

const { notify } = useNotification();

const props = defineProps([
  "collaboratorId",
  "email",
  "name",
  "brandAccess",
  "brands",
]);
const emit = defineEmits(["success"]);

const queryClient = useQueryClient();

const formSchema = toTypedSchema(
  z.object({
    brands: z.record(z.string(), z.string()),
  }),
);

const { mutate: updateCollaborator } = useUpdateCollaboratorMutation();
const { mutate: deleteCollaborator } = useDeleteCollaboratorMutation();

const getInitialValues = () => {
  const brands = {};

  props.brands.forEach((brand) => {
    const existingAccessLevel =
      props.brandAccess.find((b) => b.id === brand.id)?.role || "none";

    brands[brand.id] = existingAccessLevel;
  });

  return { brands };
};

const form = useForm({
  validationSchema: formSchema,
  initialValues: getInitialValues(),
});

const isSubmitting = ref(false);

const onSubmit = form.handleSubmit(async (values) => {
  if (isSubmitting.value) return;

  let givingBrandAccess = false;

  props.brands.forEach((brand) => {
    if (values.brands[brand.id] !== "none") {
      givingBrandAccess = true;
    }
  });

  if (!givingBrandAccess) {
    isSubmitting.true = true;

    deleteCollaborator(
      { collaboratorId: props.collaboratorId },
      {
        onSuccess: async (data) => {
          isSubmitting.value = false;
          await queryClient.refetchQueries(["collaborators"]);
          notify({
            type: "success",
            text: `No longer sharing brands with ${data.name}`,
          });
          emit("success");
        },
        onError: async (data) => {
          isSubmitting.value = false;

          notify({ type: "error", text: "Something went wrong" });
        },
      },
    );

    return;
  }

  isSubmitting.value = true;

  updateCollaborator(
    { collaboratorId: props.collaboratorId, ...values },
    {
      onError: (error) => {
        isSubmitting.value = false;

        let errorMessage = "Something went wrong";

        notify({ type: "error", text: errorMessage });
      },
      onSuccess: async () => {
        console.log("Hell yeah");

        await queryClient.refetchQueries(["collaborators"]);

        notify({
          type: "success",
          text: `Permissions updated for <strong>${props.email}</strong>`,
        });

        emit("success");
      },
    },
  );
});
</script>

<template>
  <form @submit="onSubmit" novalidate class="flex flex-col gap-8">
    <FormField v-slot="{ componentField }" name="brands">
      <BrandAccessSelect :brands="brands" v-bind="componentField" />
    </FormField>

    <AccessChangeWarning
      :brands="[...brands]"
      :newAccess="form.values.brands"
      :editingOwnAccess="false"
      :initialAccessLevels="getInitialValues().brands"
      :name="name"
      :email="email"
    />

    <Button :loading="isSubmitting" size="lg" type="submit"> Invite </Button>

    <Text class="text-center" variant="body_light_sm"
      >We will send email informing them of the change in their access.</Text
    >
  </form>
</template>
